body {
  font-family: "Montserrat", sans-serif;
  height: 100vh;
  background: url("assets/bottom-bg.svg") bottom no-repeat;
  background-size: 100% auto;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.description-container {
  background-image: url("assets/girl.png");
  background-position: bottom left;
  background-repeat: no-repeat;
  background-color: #e4ebf7;
  border-radius: 8px;
  padding: 4rem 2rem 4rem 16rem;
}

.description2-container {
  background: url("assets/thermometer.svg") no-repeat right;
  min-height: 220px;
  padding-right: 7rem;
}

.time-container {
  border: 1px solid #a5b5e5;
  border-radius: 4px;
  padding: 1rem;
  margin: 1rem 0;
  cursor: pointer;
  h2 {
    color: #597ef7;
  }
}

.time-container.selected {
  background: #e4ebf7;
}

.time-container.disabled {
  background: rgb(240, 242, 249);
  opacity: 0.5;
  cursor: default;
  h2 {
    color: rgb(106, 109, 129);
  }
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
