.wrapper{
  height: 64px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.24);
  .header{
    height: 64px;
    .logo{
      font-weight: bold;
    }
    a{
      color: #4E5C93;

      text-decoration: none;
    }
  }
}