.default{
  background-color: #FAFAFA!important;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14)!important;
  text-align: left!important;
  width: 440px;
  margin-bottom: 24px;
}
.active{
  background-color: #ffffff;
}

.card_content{
  padding-left: 72px!important;;
}

.service_name{
  color: #4E5C93;
  font-weight: 750;
  font-size: 18px;
}

.service_result{
  color: #6A6D81;
  font-weight: 800;
  font-size: 14px;
  margin-bottom: 5px;
}

.service_description{
  font-size: 14px;
  line-height: 24px;
  color: #6A6D81;
  margin-bottom: 8px;
}

.service_amount{
  font-size: 12px;
  color: #253368;
}

.service_amount_value{
  color: rgb(89, 126, 247)
}